import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TournamentTemplate from '../components/TournamentTemplate/index.js'

const TournamentPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <TournamentTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        section1_title={post.frontmatter.section1_title}
        offerings={post.frontmatter.offerings}
      />
    </Layout>
  )
}

TournamentPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TournamentPage

export const tournamentPageQuery = graphql`
  query TournamentPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        meta_title
        meta_description
        title
        subtitle
        section1_title
        offerings {
          blurbs {
            image
            text
            title
          }
        }
      }
    }
  }
`
