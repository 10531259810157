import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './index.css'

function PointTotals (cardName, { items }) {
  const totals = {
    gryffinPuff: 0,
    slytherin: 0,
    ravenclaw: 0,
  }


  if (cardName.items == null) {
    return (
      <p className='subtitle is-2 has-text-white'>
        0
      </p>
    )
  }

  // iterate through the cardName.items list and sum each item type
  for (let i = 0; i < cardName.items.length; i++) {
    const item = cardName.items[i].data

    const points = parseInt(item.Points)


    if (item.House == ("House Kai / Gryffinpuff") && !Number.isNaN(points)) {
      totals.gryffinPuff += points;
    } else if (item.House == ("House Ciz / Slytherin") && !Number.isNaN(points)) {
      totals.slytherin += points;
    } else if (item.House == ("House Dad / Ravenclaw") && !Number.isNaN(points)) {
      totals.ravenclaw += points;
    } 
  }

  console.log(totals);

  // format totals with commas for display
  for (const itemType in totals) {
    const total = totals[itemType]
    if (!Number.isNaN(total)) {
      totals[itemType] = Number(total).toLocaleString()
    }
  }

  if (cardName.cardName == "Gryffinpuff") {
    return (
      <p className='title has-text-white'>
        {totals.gryffinPuff}
      </p>
    )
  } else if (cardName.cardName == "Ravenclaw") {
    return (
      <p className='title has-text-white'>
        {totals.ravenclaw}
      </p>
    )
  } else if (cardName.cardName == "Slytherin") {
    return (
      <p className='title has-text-white'>
        {totals.slytherin}
      </p>
    )
  } else return(<p></p>)
}

const TournamentCards = ({ gridItems, className }) => {
  const data = typeof window !== 'undefined' && window.location.pathname.indexOf('admin') > 0 ? null : 
  useStaticQuery(graphql`
    query TournamentQuery {
      TotalData: allAirtable(
        filter: { table: { eq: "Developer Team Tournament" } }
      ) {
        nodes {
          data {
            House
            Points
          }
          recordId
        }
      }
    }
    `)

  if(typeof window !== 'undefined' || typeof document !== 'undefined'){
    if (window.location.pathname.indexOf('admin') > 0) {
      AOS.init({
        disable: false,
      });
    } else AOS.init({disable: false});
  }
  return (
    <div className='columns is-multiline is-centered'>
      {gridItems.map(item => (
        <div key={item.image} className={className} style={{ borderRadius: '5px' }}>
          <div className='card'>
            <div className='card-image'>
              <figure className='image'>
                <img className='card-img' src={item.image} alt={item.title} />
              </figure>
            </div>
            <div className='card-content profile'>
              <div className='media'>
                <div className='media-content'>
                  <p className='title is-4'>{item.title}</p>
                  <PointTotals cardName={item.title} items={data == null ? null : data.TotalData.nodes}/>
                </div>
              </div>
              <div className='content' id='cardcontent'>
                {item.text}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

TournamentCards.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
  ),
}

export default TournamentCards
