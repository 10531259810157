// TournamentTemplate/index.js
//
// React Component for main content of About Us page
// URLs: /about/

import React from 'react'
import TournamentCards from '../TournamentCards'
import PropTypes from 'prop-types'
import './index.css'

import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
if(typeof window !== 'undefined' || typeof document !== 'undefined'){
  if (window.location.pathname.indexOf('admin') > 0) {
    AOS.init({
      disable: false,
    });

  } else AOS.init({disable: false});
}

function getWindowDimensions() {
  if (typeof windowGlobal === 'undefined') {
    return 600
  } else {
    const { innerWidth: width } = windowGlobal
    return {
      width,
    }
  }
}

const windowGlobal = typeof window !== 'undefined' && window

const TournamentTemplate = ({ title, subtitle, section1_title, offerings }) => {

  const { width } = getWindowDimensions()

  return (
    <div>
      <section className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title' data-aos='zoom-out' data-aos-duration='1000'>
                    {title}
                  </h1>
                  <h2 className='subtitle' data-aos='zoom-out' data-aos-duration='1000'>
                    {subtitle}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section3'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h5 className='styledHeader is-centered'>Ways to earn points</h5>
                <br />
                <div class="level mt-5">
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading has-text-white">Attendance</p>
                      <p class="heading has-text-white">+2 On Time, +1 Late</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading has-text-white">Shoutouts</p>
                      <p class="heading has-text-white">+1, 1 to give / person / week</p>
                    </div>
                  </div>
                </div>
                <div class="level mt-5">
                <div class="level-item has-text-centered">
                    <div>
                      <p class="heading has-text-white">Deadlines</p>
                      <p class="heading has-text-white">+2 / day early, -4 / day late</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading has-text-white">Games</p>
                      <p class="heading has-text-white">+3 for 1st, +2 for 2nd, +1 for 3rd</p>
                    </div>
                  </div>
                </div>
                <div class="level mt-5">
                <div class="level-item has-text-centered">
                    <div>
                      <p class="heading has-text-white">Code Review</p>
                      <p class="heading has-text-white"> +2 for 24 hours review, +3 for 24 hours implementation</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
     
      <section className='section3'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h5 className='styledHeader is-centered'>{section1_title}</h5>
                <br />
                <TournamentCards gridItems={offerings.blurbs} className='column is-4' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

TournamentTemplate.propTypes = {
  isCMS: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  section1_title: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

export default TournamentTemplate
